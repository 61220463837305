import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  DaySchedule,
  GetOperationalDetailsOperationalDetails,
} from "@platform-app/app/core/api/models";
import { ClinicService } from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { tap, catchError, finalize } from "rxjs";

export interface OperationalDetailsStateModel {
  operationalDetails: GetOperationalDetailsOperationalDetails | null;
  dataLoading: boolean;
  saveLoading: boolean;
  error: string | null;
}

export class GetOperationalDetails {
  static readonly type = "[Operational Details Page] Get Operational Details";
}

export class UpdateOperationalDetails {
  static readonly type =
    "[Operational Details Page] Update Operational Details";
  constructor(
    public payload: {
      body?: {
        daySchedules: DaySchedule[];
        timeZoneId: number;
        workingScheduleComment?: string | null;
      };
    },
  ) {}
}

@State<OperationalDetailsStateModel>({
  name: "OperationalDetails",
  defaults: {
    operationalDetails: null,
    dataLoading: false,
    saveLoading: false,
    error: null,
  },
})
@Injectable()
export class OperationalDetailsState {
  constructor(
    private clinicService: ClinicService,
    private store: Store,
  ) {}

  @Selector()
  static operationalDetails(state: OperationalDetailsStateModel) {
    return state.operationalDetails;
  }

  @Selector()
  static saveLoading(state: OperationalDetailsStateModel) {
    return state.saveLoading;
  }

  @Selector()
  static error(state: OperationalDetailsStateModel) {
    return state.error;
  }

  @Action(GetOperationalDetails)
  getClinicOperationDetails(ctx: StateContext<OperationalDetailsStateModel>) {
    const clinicId = this.store.selectSnapshot(AuthState.user)?.organization
      ?.id;

    if (!clinicId) {
      throw new Error("Current User does not have Organization id");
    }

    ctx.patchState({
      dataLoading: true,
      error: null,
    });

    return this.clinicService
      .clinicClinicIdOperationalDetailsGet({ clinicId })
      .pipe(
        tap((response) => {
          ctx.patchState({
            operationalDetails: response,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error.error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            dataLoading: false,
          });
        }),
      );
  }

  @Action(UpdateOperationalDetails)
  updateOperationalDetails(
    ctx: StateContext<OperationalDetailsStateModel>,
    action: UpdateOperationalDetails,
  ) {
    const clinicId = this.store.selectSnapshot(AuthState.user)?.organization
      ?.id;

    if (!clinicId) {
      throw new Error("Current User does not have Organization id");
    }

    ctx.patchState({
      saveLoading: true,
      error: null,
    });

    return this.clinicService
      .clinicClinicIdOperationalDetailsPut({
        clinicId,
        body: action.payload.body,
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            operationalDetails: {
              ...action.payload.body,
              clinicId,
            },
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error: error.error,
          });
          throw error;
        }),
        finalize(() => {
          ctx.patchState({
            saveLoading: false,
          });
        }),
      );
  }
}
